@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.neighbor {
	background-color: $color-one;
	border-radius: .25rem;
	padding: 0.25rem 1.5rem 0.5rem;
	display: flex;
	flex-wrap: wrap;
	color: $color-six;
	box-shadow: 1px 1px 2px 1px $color-four;
	max-width: 25rem;	
	min-width: 16rem;
	align-self: stretch;
	flex: 1;

	@include tablet {
		align-self: stretch;
	}

	&__header {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		width: 100%;
		justify-content: space-between;
		padding-top: 1rem;
	}

	&__title {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		text-transform: capitalize;
		@include bodyCopyLarge;
	}

	&__member-since {
		@include labelLarge;
		width: 100%;
		padding-top: max(0.5rem, 0.125rem);
	}

	&__since {
		@include labelLarge;
		color: $color-five;
		font-weight: 600;
	}

	&__img {
		width: 110%;
		height: 110%;
		object-fit: cover;
		object-position: center;
	}

	&__img-box {
		width: 5rem;
		height: 5rem;
		overflow: hidden;
		border-radius: 0.25rem;
		background-color: $color-five;
	}

	&__bio {
		display: block;
		width: 100%;
		padding: 0.125rem 0 0.25rem;

		@include tablet {
			padding: 0.25rem 0 0.75rem;
		}
	}

	&__barter-title {
		@include bodyCopy;
		color: $color-six;
		margin: 0;
	}

	&__barter-skills {
		display: flex;
		flex-wrap: wrap;
	}

	&__barter-skill {
		@include bodyCopyLarge;
		list-style-type: none;
		margin: .125rem .25rem;
		padding: 0 0.25rem;
		font-size: max(1rem, 0.75rem);
		background-color: $color-two;
		color: $color-six;
		border-radius: 0.25rem;
		box-shadow: .5px .5px 0.5px 0.5px $color-four;
	}

	&__message-link {
		@include bodyCopy;
		height: fit-content;
		color: $color-one;
		text-decoration: underline;
		background-color: $color-five;
		border-radius: 0.25rem;
		padding: 0.25rem 0.5rem;
		margin: .125rem 0;
		transition: all 0.125s ease-in-out;
		align-self:flex-start;

		&:hover {
			background-color: $color-two-light;
			color: $color-five;
			box-shadow: 0px 0px 1px 1px $color-five;
		}
	}

	&__placeholder {
		height: 100vh;
	}
}

a {
	text-decoration: none;
}

.semibold {
	font-weight: 600;
	color: $color-five;
	text-transform: capitalize;
}
