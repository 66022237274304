$tablet-breakpoint: 48rem;
$desktop-breakpoint: 80rem;

$color-one: #f4feff;
$color-two: #cff0ff;
$color-two-light: #e3faff;
$color-three: #12e7ee;
$color-four: #0085a7;
$color-five: #0c2241;
$color-six: #121313;
$color-error: #e6394670;
$color-success: #2a9d3050;

$box-padding-mobile: 0.5rem 1rem;
$box-padding-tablet: 1rem 1.5rem;
$box-padding-desktop: 1.5rem;
