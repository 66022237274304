@use "./variables" as *;

// TODO : Add media queriis for font changes to this file

@mixin tablet {
	@media (min-width: $tablet-breakpoint) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: $desktop-breakpoint) {
		@content;
	}
}

/*font mixins*/
@mixin pageHeader {
	font-size: 2rem;
	line-height: 2.5rem;
	letter-spacing: 0.075rem;
	font-weight: 600;
	margin: 0;
}

@mixin pageHeaderLarge {
	font-size: 2.75rem;
	line-height: 3.75rem;
	letter-spacing: 0.1rem;
	font-weight: bold;
	margin: 0;
}

@mixin sectionHeader {
	font-size: 1.5rem;
	line-height: 1.75rem;
	font-weight: 600;
	margin: 0;
}

@mixin sectionHeaderLarge {
	font-size: 2.25rem;
	line-height: 2.25rem;
	font-weight: 600;
	margin: 0;
}

@mixin subHeader {
	font-size: 1rem;
	line-height: 1.125rem;
	font-weight: bold;
	margin: 0;
}

@mixin subHeaderLarge {
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: bold;
	margin: 0;
}

@mixin bodyCopy {
	font-size: 0.8125rem;
	line-height: 1.25rem;
	font-weight: normal;
	margin: 0;
}

@mixin bodyCopyLarge {
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: normal;
	margin: 0;
}

@mixin label {
	font-size: 0.625rem;
	line-height: 1.25rem;
	font-weight: 600;
	margin: 0;
}

@mixin labelLarge {
	font-size: 0.75rem;
	line-height: 1.25rem;
	font-weight: 600;
	margin: 0;
}

@mixin validLabel {
	@include label;
	padding: 0.25rem;
	background-color: $color-success;
	margin-bottom: .5rem;
	border-radius: 0.25rem;
}

@mixin tablet {
	@media (min-width: $tablet-breakpoint) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: $desktop-breakpoint) {
		@content;
	}
}

@mixin flexin($direction: row) {
	display: flex;
	flex-direction: $direction;
}
