@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.header {
	width: 100%;
	background-color: $color-one;
	display: flex;

	&__container {
		display: flex;
		padding: $box-padding-mobile;
		padding-bottom: 0;
		justify-content: center;
		align-items: center;
		width: 100%;
		flex-direction: column;
		@include tablet {
			padding: $box-padding-tablet;
			padding-bottom: 0;

			justify-content: space-between;
		}
		@include desktop {
			flex-direction: row;
			height: 7.5rem;
			justify-content: space-between;
			align-items: flex-end;
			padding: $box-padding-desktop;
			padding-bottom: 0;
		}
	}

	//header title and subtitle
	&__titlebox {
		display: flex;
		align-items: flex-end;
	}

	&__title-btn {
		text-decoration: none;
		color: $color-five;
		transition: all 0.125s ease-out;
		padding: 0 0 0.5rem;

		&:hover {
			color: $color-four;
		}
	}

	&__title {
		@include pageHeaderLarge;
		display: block;
	}

	&__subtitle {
		@include sectionHeaderLarge;
		display: block;
	}

	//tagline
	&__tagline-box {
		@include bodyCopy;
		font-size: 0.75rem;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		align-items: center;
		color: $color-four;
		font-weight: 600;
		padding: $box-padding-mobile;
		font-size: clamp(1rem, 1.5vw, 1.25rem);
		@include tablet {
			flex-direction: row;
			align-items: flex-end;
			padding: 0 0 0.625rem 2rem;
		}
	}

	&__tagline {
		@include tablet {
			margin: 0 1rem 0 0;
		}
	}

	//login button box
	&__loginbox {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	//navigation menu box
	&__navbox {
		display: flex;
		align-items: flex-end;
		width: 100%;
		justify-content: center;
		@include desktop {
			justify-content: flex-end;
			width: fit-content;
		}
	}

	//header buttons
	&__btn {
		@include label;
		color: $color-one;
		background-color: $color-five;
		border: none;
		border-radius: 0.25rem;
		padding: 0.375rem;
		flex-basis: auto;
		margin: 0 0.125rem;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		text-decoration: underline;
		text-align: center;
		font-size: 0.75rem;
		flex-grow: 1;
		@include tablet {
			@include bodyCopy;
			flex-grow: 0;
			font-weight: 600;
			letter-spacing: 0.025rem;
			font-size: 0.875rem;
			padding: 0.5rem;
			margin: 0 0 0 1rem;
			width: fit-content;
		}
		@include desktop {
			flex-grow: 0;
			padding: 1rem;
		}

		&:hover {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			color: $color-five;
			background-color: $color-two-light;
			box-shadow: 0px -1px 0px 1px $color-five;
		}
	}
}

.active {
	background-color: $color-two;
	color: $color-six;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	box-shadow: 0px -1px 0px 1px $color-five;
}

.fixed {
	width: 5rem;
}

.loggedin {
	background-color: $color-two;
	color: $color-six;
}
