@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.neighbors {
	background-color: $color-two;
	display: flex;
	flex-direction: column;
	align-content: space-around;
	gap: 2rem;
	padding: 1rem 1rem 0;
	flex-wrap: wrap;

	@include tablet {
		padding: 3rem 3rem 0;
		flex-direction: row;
	}

	&--alt {
		background-color: $color-one;
		border-radius: 0.25rem;
		padding: 1rem 0.5rem 0;
		margin: 0.5rem 1rem 0;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		

		@include tablet {
			margin: 3rem;
			padding: 1rem;
			flex-direction: row;
		}
	}

	&__title {
		@include sectionHeader;
		color: $color-five;
		text-align: center;
		margin: 1rem 0.5rem 0;

		@include tablet {
			margin: 2rem 1rem 0;
		}
	}

	&__subtitle {
		@include subHeader;
		color: $color-six;
		margin: 0 0.5rem 1rem;

		@include tablet {
			margin: 0rem 1rem 1.5rem;
		}
	}

	&__paragraph {
		@include bodyCopyLarge;
		color: $color-six;
		padding-bottom: 1rem;

		@include tablet {
			padding-bottom: 2rem;
		}
	}

	&__tip {
		@include bodyCopy;
		color: $color-five;
		padding-bottom: 1rem;

		@include tablet {
			padding-bottom: 2rem;
		}
	}
}

.neighbor__link {
	width: 100%;
	height: fit-content;
	transition: all 0.125s ease-in-out;

	&:hover {
		box-shadow: 0px 0px 3px 4px $color-four;
		border-radius: 0.375rem;
	}

	@include tablet {
		width: clamp(18.75rem, 25%, 32rem);
	}
}
