@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	padding: $box-padding-mobile;

	@include tablet {
		padding: $box-padding-tablet;
	}

	@include desktop {
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		gap: 3rem;
		padding: $box-padding-desktop;
	}

	&__title {
		@include sectionHeader;
		color: $color-five;
		text-align: center;
		margin: 1rem 0.5rem 0;
		@include desktop {
			margin: 2rem 1rem 0;
		}
	}

	&-info {
		background-color: $color-one;
		color: $color-six;
		padding: $box-padding-mobile;
		border-radius: 0.375rem;
		display: flex;
		max-width: 60rem;
		min-width: 15.625rem;
		flex-direction: column;
		text-align: right;
		align-items: flex-end;

		@include tablet {
			padding: $box-padding-tablet;
		}
		@include desktop {
			height: 21rem;
			padding: $box-padding-desktop;
		}

		&__qa {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			max-width: 50rem;
		}

		&__quest {
			@include subHeaderLarge;
			color: $color-five;
			text-align: center;
		}

		&__answer {
			@include bodyCopyLarge;
			text-align: left;
		}
	}

	&-form {
		color: $color-five;
		background-color: $color-one;
		padding: $box-padding-mobile;
		border-radius: 0.375rem;
		display: flex;
		flex-direction: column;
		order: -1;
		width: 100%;

		@include tablet {
			padding: $box-padding-tablet;
			width: auto;
			min-width: 18rem;
		}

		@include desktop {
			text-align: center;
			padding: $box-padding-desktop;
			order: 0;
			// height: 21rem;
		}

		&__box {
			display: flex;
			flex-direction: column;
		}

		&__input {
			border-radius: 0.25rem;
			border: 1px solid $color-five;
			padding: 0.25rem;
			margin: 0.25rem 0 0.5rem;

			@include tablet {
				margin: 0.5rem 0 0 0;
			}
		}

		&__btn {
			@include bodyCopy;
			text-decoration: underline;
			color: $color-six;
			font-weight: 600;
			margin: 0.5rem 0;
			border: 1px solid $color-five;
			padding: 0.5rem 1.5rem;
			border-radius: 0.25rem;
			flex-basis: 33%;
			background-color: $color-two-light;
			transition: all 0.125s ease-out;
			flex-grow: 1;

			&:hover {
				background-color: $color-five;
				color: $color-one;
				border-color: $color-two-light;
				cursor: pointer;
			}

			&:disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}

		&__valid-label {
			@include validLabel;

			&--active {
				background-color: $color-error;
			}
		}
	}
}

.signup {
	text-align: center;
}

.login-error {
	background-color: $color-error;
	color: $color-one;
	margin: 0 0 0.25rem;
	padding: 0.125rem 0.25rem;
	border-radius: 0.25rem;
}
