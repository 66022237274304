@use "../../styles/global" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.edit {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__title {
		@include sectionHeader;
		color: $color-five;
		text-align: center;
		margin: 1rem 0.5rem 0;

		@include tablet {
			margin: 2rem 1rem 0;
		}
	}

	&__form {
		color: $color-five;
		display: flex;
		gap: 3rem;
		width: 100%;
		flex-direction: column;
		align-items: space-around;
		padding: 0.5rem 1rem 0;

		@include tablet {
			padding: 3rem;
		}

		@include desktop {
			flex-direction: row;
		}

		&-error {
			@include sectionHeader;
			background-color: $color-error;
			color: $color-six;
			margin: 0 0 0.25rem;
			padding: 0.125rem 0.25rem;
			border-radius: 0.25rem;
			text-align: center;
		}
	}

	&__signup {
		flex-basis: 30%;
		background-color: $color-one;
		border-radius: 0.25rem;
		padding: 0.5rem 1rem 0;

		@include tablet {
			padding: 2rem;
		}
	}

	&__personalize {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;
		background-color: $color-one;
		border-radius: 0.25rem;
		padding: 0.5rem 1rem;

		@include tablet {
			padding: 2rem;
		}
	}

	&__label {
		display: flex;
		gap: 0.25rem;
		padding: 1rem 0;
		justify-content: space-between;
		flex-direction: column;

		@include tablet {
			flex-direction: row;
		}
	}

	&__input {
		width: 100%;
		flex-basis: 70%;
		border-radius: 0.25rem;
		border: 1px solid $color-five;
		padding: 0.125rem 0.5rem;

		@include tablet {
			width: 15rem;
		}
	}

	&__desc {
		@include bodyCopy;
		color: $color-six;
		text-align: left;

		@include tablet {
			margin: -0.5rem 0 0;
		}

		@include desktop {
			margin: -1rem 0 0;
		}
	}

	&__btn-container {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 0.25rem;

		@include tablet {
			flex-direction: row;
			gap: 1rem; 
		}
	}

	&__btn {
		@include labelLarge;
		align-self: center;
		text-decoration: none;
		text-align: center;
		color: $color-six;
		font-weight: 600;
		border: 1px solid $color-five;
		padding: 0.5rem 1.5rem;
		border-radius: 0.25rem;
		margin: 0.5rem 0 0;
		background-color: $color-two-light;
		transition: all 0.125s ease-out;
		flex-grow: 1;
		
		&:hover {
			background-color: $color-five;
			color: $color-one;
			border-color: $color-two-light;
			cursor: pointer;
		}
	}

	&__password {
		display: none;
		flex-wrap: wrap;

		& label {
			width: 100%;
		}

		& p {
			width: 100%;
		}
	}
}

.textarea {
	resize: none;
}

.delete__btn {
	width: 100%;
	color: $color-five;
	background-color: $color-error;
}
